import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { contentHolder, exampleSection, carouselImage } from "./services.module.css"
import { Row, Col, ListGroup, Carousel } from "react-bootstrap"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"

const ResidentialPage = ({ data, services }) => {
  return (
    <Layout>
      <Seo title="Residential Construction" />
      <div className={contentHolder}>
        <h2 className={"page-title"}>Residential Construction</h2>
        {/* <Row className={highlightRow}>
        <Col>
          <Image
            fluid
            src="http://placehold.jp/902x315.png"
          />
        </Col>
      </Row> */}
        <Row>
          <Col>
            <Carousel>
              {data.allFile.edges.map(({ node }, i) => {
                return (
                  <Carousel.Item key={i}>
                    <GatsbyImage className={carouselImage} image={node.childImageSharp.gatsbyImageData} alt="" />
                  </Carousel.Item>)
              })}
            </Carousel>
          </Col>
        </Row>
        <Row>
          <Col className={contentHolder}>
            <Row>
              <Col>
                <p>
                  We offer all the best that any home builder could want. We have
                  been developing the system we use since 2015. Ask any
                  builder we work with, and they’ll tell you we significantly
                  over-deliver on our promises.
            </p>
                <p>Just a few of the perks of our system:</p>
                <ul>
                  <li>Automated punch lists when our staff walk post job</li>
                  <li>
                    Notification to your subdivision supervisors when we enter and
                    leave
              </li>
                  <li>
                    Much more, inquire to learn more and have a plan tailor made to
                    you!
              </li>
                </ul>
              </Col>
            </Row>
            <Row>
              <Col className={exampleSection + " text-center"}>
                <h3>Some of the builders we work with</h3>
              </Col>
            </Row>
            <Row>
              <Col>
                <h4 className="text-center">Production Builders</h4>
                <ListGroup variant="flush">
                  <ListGroup.Item>Silverstone</ListGroup.Item>
                  <ListGroup.Item>Steven Elliot</ListGroup.Item>
                  <ListGroup.Item>Brock Built</ListGroup.Item>
                  <ListGroup.Item>JT Development</ListGroup.Item>
                  <ListGroup.Item>S & A Construction</ListGroup.Item>
                </ListGroup>
              </Col>
              <Col>
                <h4 className="text-center">Custom Home Builders</h4>
                <ListGroup variant="flush">
                  <ListGroup.Item>WD Thomas</ListGroup.Item>
                  <ListGroup.Item>LBM Custom Homes</ListGroup.Item>
                  <ListGroup.Item>Royal T Homes & Realty</ListGroup.Item>
                  <ListGroup.Item>Oak Southern Homes</ListGroup.Item>
                </ListGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Layout>
  );
}

export default ResidentialPage

export const ResidentialPageQuery = graphql`query ResidentialPageQuery {
  allFile(
    filter: {extension: {eq: "png"}, dir: {regex: "/highlights\\/residential/"}}
  ) {
    edges {
      node {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  }
}`
